<template>
  <div class="inspection-exception-list">
    <van-nav-bar left-arrow title="异常管理" @click-left="$router.back()" />
    <div class="search-query">
      <van-search
        v-model="queryInfo.recordName"
        class="search-bar"
        placeholder="请输入任务名称"
        @clear="onRefresh"
        @search="onRefresh"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="datetime"
        format="{y}-{m}-{d} {h}:{i}"
      />
      <inspection-exception-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}条结果</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="inspection-exception-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="[
            'inspection-exception-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="gotoDetails(item)"
        >
          <div class="row-core">
            <div class="title">
              <span class="tit">{{ item.recordName }}</span>
              <span
                v-if="item.abnormalWay !== 0"
                :class="['tag', `tag-${item.abnormalWay}`]"
                >{{ item.abnormalWayName || "--" }}</span
              >
            </div>
            <div class="content">
              <div class="left">
                <div class="top">
                  <span class="mini-icon-wrapper">
                    <van-icon
                      class-prefix="iconfont"
                      class="mini-icon"
                      name="guankongcengji"
                    />
                    <b>{{ item.taskTypeName || "--" }}</b>
                  </span>
                  <span class="mini-icon-wrapper">
                    <van-icon
                      class-prefix="iconfont"
                      class="mini-icon"
                      name="yonghu1"
                    />
                    <b>{{ item.executePersonName || "--" }}</b>
                  </span>
                </div>
                <div class="bottom">
                  <span class="mini-icon-wrapper">
                    <van-icon
                      class-prefix="iconfont"
                      class="mini-icon"
                      name="bumen"
                    />
                    <b>{{ item.departmentName || "--" }}</b>
                  </span>
                </div>
              </div>
              <div class="right">
                <van-icon
                  v-if="item.abnormalWay === 0"
                  name="daichuli"
                  class-prefix="iconfont"
                  color="#F78649"
                />
                <van-icon
                  v-else
                  name="yichuli"
                  class-prefix="iconfont"
                  color="#9B9B9B"
                />
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getPageInspectException } from "@/api/psm/inspectionNew";
import InspectionExceptionQuery from "./components/InspectionExceptionQuery";
import dayjs from "dayjs";

export default {
  name: "InspectionException",
  components: { InspectionExceptionQuery },
  data() {
    return {
      queryInfo: {
        recordName: "",
        orgCode: "",
        startTime: "",
        endTime: "",
        department: "",
        executePersonId: "",
        taskType: "",
        abnormalWay: "",
        sort: "validStartTime_desc",
        page: 1,
        size: 10
      },
      dateTime: { start: "", end: "" },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.queryInfo.startTime = this.formatDate(val.start);
          this.queryInfo.endTime = this.formatDate(val.end);
          console.log("????");
          this.onRefresh();
        }
        if (!val.start && !val.end) {
          this.queryInfo.startTime = "";
          this.queryInfo.endTime = "";
          this.onRefresh();
        }
      },
      deep: true
    }
  },
  created() {
    this.queryInfo.orgCode = this.userInfo.orgCode;
  },
  activated() {
    this.onRefresh();
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const data = await getPageInspectException(this.queryInfo);
        const { list, total } = data || { list: [], total: 0 };
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoDetails({ id }) {
      this.$router.push({ name: "InspectionExceptionDetail", params: { id } });
    }
  }
};
</script>

<style lang="scss">
.inspection-exception-list {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .search-bar {
    padding-bottom: 0;
  }

  .trouble-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    padding: 14px 0;
  }

  &__wrapper {
    overflow-y: auto;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    .title {
      color: #2e2e4d;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .left {
      display: flex;
      flex-direction: row;
    }
    .mini-icon-wrapper {
      margin-right: 12px;
      display: flex;
      align-items: center;
      width: 130px;
      b {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: #a0a2a6;
        width: 110px;
        line-height: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .mini-icon {
      font-size: 13px;
      color: #aeb3c0;
      margin-right: 4px;
    }
    .row-core {
      .title {
        display: flex;
        align-items: center;
        .tit {
          font-size: 14px;
          max-width: 280px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .tag {
        white-space: nowrap;
        font-size: 12px;
        color: #fff;
        padding: 2px 6px;
        line-height: 12px;
        border: 1px solid #1676ff;
        border-radius: 2px;
        background-color: #1676ff;
        vertical-align: middle;
        margin-left: 4px;
      }
      .tag-1 {
        background-color: #fb7171;
        border-color: #fb7171;
      }
      .tag-2 {
        background-color: #f3961c;
        border-color: #f3961c;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right {
          width: 44px;
          height: 44px;
          line-height: 44px;
          i {
            font-size: 40px;
          }
        }
      }
    }
  }
}

.inspection-exception-list__rows {
  padding: 10px 16px;
  border-bottom: 8px solid #eceef2;
}
</style>
